import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { setRedirectUrl } from '../../store/slices/auth';
import { setNotification } from '../../store/slices/notification';
import { getToken, logout } from '../../utils/auth';

interface ImyAxios extends AxiosInstance {
  store?: any;
}

const interceptor = () => {
  const newAxios: ImyAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_DEV,
  });

  newAxios.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = getToken();
    if (config && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  newAxios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        const { pathname, search } = window.location;
        newAxios.store?.dispatch(setRedirectUrl(pathname + search));
        logout(newAxios.store?.dispatch);
        newAxios.store?.dispatch(
          setNotification({ message: 'Unauthorized: Please login again', type: 'error' })
        );
      }

      return Promise.reject(error);
    }
  );
  return newAxios;
};
const $api: ImyAxios = interceptor();
export default $api;
