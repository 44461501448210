import React from 'react';
import { useSelector } from 'react-redux';

import Button from '../../../../features/atoms/Button';
import { getCurrentActivity } from '../../../../store/selectors/studySelectors';
import { ICurrentActivity, IVocabItemData } from '../../../../types/study';
import styles from './vocabulary.module.scss';
import WordRow from './WordRow';

interface IVocabularyPrologue {
  data: Array<IVocabItemData>;
  setIsQuizStarted: any;
}

const VocabularyPrologue: React.FC<IVocabularyPrologue> = ({ data, setIsQuizStarted }) => {
  const { text }: ICurrentActivity = useSelector(getCurrentActivity);

  return (
    <div className={styles.vocabularyWordList}>
      <h1>{text}</h1>
      {data?.map(({ _id, meanings, course, spelling, url }) => {
        return (
          <WordRow
            key={_id}
            text={spelling}
            subText={meanings}
            sound={url}
            url={url}
            course={course}
          />
        );
      })}
      <Button type={'primary'} value={'Start the quiz'} onClick={() => setIsQuizStarted(true)} />
    </div>
  );
};

export default VocabularyPrologue;
