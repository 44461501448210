/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import IconFlagReport from '../../../assets/imgComponent/IconFlagReport';
import { SCREENS } from '../../../constants/sccrens';
import Button from '../../../features/atoms/Button';
import ImageTestOptions from '../../../features/atoms/ImageTestOptions';
import StepButton from '../../../features/atoms/StepButton';
import TestRow from '../../../features/atoms/TestRow.tsx';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useUserCourses } from '../../../hooks/useUserCourses';
import { getIsReviewLoading } from '../../../store/selectors/review';
import {
  getActivities,
  getActivitiesDataFromStore,
  getCurrentLevel,
  getLanguageDataFromStore,
  getLessons,
} from '../../../store/selectors/studySelectors';
import { getUser } from '../../../store/slices/auth';
import { setModal, setModalType } from '../../../store/slices/modal';
import { setNotification } from '../../../store/slices/notification';
import { getReviewSkillsData, setLastReviewData } from '../../../store/slices/review';
import {
  getIsAnswerCorrect,
  getLevelData,
  markAsDone,
  setCurrentActivity,
  setCurrentLessonAndLevel,
  setGivenPoint,
} from '../../../store/slices/study';
import { MODAL_TYPES } from '../../../types/modals';
import { IQuizWordData, ITestItemData } from '../../../types/study';
import {
  getLessonKey,
  getLevelKey,
  getNextActivity,
  getRandomNumber,
  playSound,
  sendReportMessage,
} from '../../../utils/study';
import Loader from '../../Loader';
import WordRow from '../../StudyComponents/StudyPage/Vocabulary/WordRow';
import HintComponent from '../HintComponent/HintComponent';
import ResultComponent from '../ResultComponent';
import ScriptTestKey from '../ScriptTestKey';
import TestKey from '../TestKey';
import styles from './testContent.module.scss';

interface ITestContentProps {
  type?: 'assessment' | 'vocab' | 'script';
  data: ITestItemData;
  step: number;
  stepsCount: number;
  setStep: (step: number) => void;
  setIsStartQuiz?: (arg: boolean) => void;
  setAllData?: (arg: any) => void;
  setQuestionsPositions?: (arg: any) => void;
  setCheckedItemsCount: any;
  id?: string;
  isReviewPage?: boolean;
  allData?: Array<any>;
  numOfQuestions?: Array<Array<number>>;
  quizWordsData?: Record<string, IQuizWordData>;
  isLastQuestion: boolean;
  reviewQuestionsCount?: number;
}
const TestContent: React.FC<ITestContentProps> = ({
  type = 'assessment',
  data,
  step,
  setStep,
  stepsCount,
  setCheckedItemsCount,
  id,
  setIsStartQuiz,
  isReviewPage,
  setAllData,
  isLastQuestion,
  allData,
  numOfQuestions,
  quizWordsData,
  setQuestionsPositions,
  reviewQuestionsCount,
}) => {
  const dispatch = useDispatch();
  const {
    _id: quizId,
    task,
    key,
    options,
    answer,
    hint,
    course,
    word,
    resultAnswer,
    image,
    taskText,
    transliteration,
    transliterationWord,
    meaningsData,
  } = data;
  let sound = data.url ?? '';
  const [variant, setVariant] = useState('');
  const [isAnswered, setIsAnswered] = useState(false);
  const [isAnsweredLoading, setIsAnsweredLoading] = useState(false);
  const { level, lesson } = useSelector(getCurrentLevel);
  const levels = useSelector(getLessons);
  const activities = useSelector(getActivitiesDataFromStore);
  const allActivities = useSelector(getActivities);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isFinish, setIsFinish] = useState(true);
  const [optionsData, setOptionsData] = useState<Array<string>>([]);
  const [keyText, setKeyText] = useState<string>('');
  const nextActivity = getNextActivity();
  const navigate = useNavigate();
  const isTablet = useMediaQuery(SCREENS.tablet);
  const isMobile = useMediaQuery(SCREENS.mobile);
  const { currentCourse } = useUserCourses();
  const languageData = useSelector(getLanguageDataFromStore);
  const isReviewIsLoading = useSelector(getIsReviewLoading);

  useEffect(() => {
    if (options.includes(answer)) {
      setOptionsData([...options].sort(() => 0.5 - Math.random()));
    } else {
      const shuffledOptions = [...options, answer].sort(() => 0.5 - Math.random());
      setOptionsData(shuffledOptions);
    }
  }, [data]);

  useEffect(() => {
    if (type === 'script' || type === 'vocab') {
      setIsFinish(false);
    }
    return () => {
      sound = '';
    };
  }, [type]);

  const handleCheckClick = async () => {
    if (variant) {
      setIsAnsweredLoading(true);
      let answer = variant;
      if (image && meaningsData) {
        answer = image ? meaningsData[variant] : variant;
      }

      try {
        const isCorrect = await getIsAnswerCorrect(answer, quizId, id, isReviewPage);
        playSound(isCorrect ? 'correct' : 'wrong');
        setIsCorrect(isCorrect);
        setIsAnswered(true);
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
        });
        if (type === 'vocab' || type === 'assessment') {
          if (isCorrect) {
            setCheckedItemsCount((prev: number) => prev + 1);
          } else if (setAllData && allData) {
            setAllData((prev: any) => [...prev, allData[step]]);
          }
        } else {
          if (isCorrect) {
            setCheckedItemsCount((prev: number) => prev + 1);
          } else if (allData && numOfQuestions && setQuestionsPositions) {
            const [currentScriptNumber] = numOfQuestions[step];
            const { words: currentScriptWords } = allData[currentScriptNumber];
            const passedItems = numOfQuestions.reduce((acc, el) => {
              if (el[0] === currentScriptNumber) {
                return [...acc, el[1]];
              } else {
                return acc;
              }
            }, []);
            if (passedItems.length >= currentScriptWords.length) {
              setQuestionsPositions([...numOfQuestions, numOfQuestions[step]]);
            } else {
              const randomNumber = getRandomNumber(currentScriptWords.length, passedItems);
              setQuestionsPositions([...numOfQuestions, [currentScriptNumber, randomNumber]]);
            }
          }
        }

        if (isReviewPage && reviewQuestionsCount) {
          if (reviewQuestionsCount > stepsCount && step === stepsCount - 1) {
            dispatch(getReviewSkillsData(currentCourse));
          }
        }
      } catch (err) {
        dispatch(setNotification({ message: 'Something went wrong', type: 'error' }));
      } finally {
        setIsAnsweredLoading(false);
      }
    }
  };

  const handleNextClick = () => {
    setStep(++step);
    setIsAnswered(false);
    setVariant('');
    let scrollHeight = 0;
    if (isMobile || isTablet) {
      const sidebarEl = document.getElementById('study_aside_menu');
      scrollHeight = sidebarEl?.offsetHeight || 0;
    }
    window.scrollTo({
      top: scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleFinishClick = () => {
    setIsFinish(true);
    setIsAnswered(false);
    setVariant('');
    setStep(0);
    setCheckedItemsCount(0);
    if (isReviewPage && setIsStartQuiz) {
      dispatch(setCurrentLessonAndLevel({}));
      dispatch(
        setCurrentActivity({
          id: 0,
          text: '',
        })
      );
      dispatch(setLastReviewData());
      setIsStartQuiz(false);
      navigate('/study');
      dispatch(setModal(true));
      dispatch(setModalType(MODAL_TYPES.wellDone));
      dispatch(setGivenPoint(0));
      dispatch(getUser());
    }
    if (lesson && level && id && !isReviewPage) {
      const levelKey = getLevelKey(level);
      const lessonKey = getLessonKey(lesson);
      const currentLessonActivities: Array<any> = allActivities[levelKey][lessonKey].data;
      const isCompleted = currentLessonActivities.find((el) => el['_id'] === id)['is_completed'];
      if (isCompleted) {
        if (nextActivity) {
          dispatch(setCurrentActivity(nextActivity));
        } else {
          dispatch(
            setCurrentActivity({
              id: 0,
              text: '',
            })
          );
        }
      } else {
        dispatch(markAsDone(id, course, level, lesson, activities, nextActivity));
        dispatch(getLevelData(course, level, levels, false));
        dispatch(setModal(true));
        dispatch(setModalType(MODAL_TYPES.wellDone));
      }
    }
  };

  return (
    <div key={quizId} className={styles.testContent}>
      <div
        onClick={() =>
          sendReportMessage(
            languageData ? languageData[currentCourse].name : currentCourse,
            level,
            lesson,
            type,
            taskText || task,
            keyText,
            optionsData,
            hint
          )
        }
        className={styles.sendReport}
      >
        <IconFlagReport />
        Report
      </div>
      {type === 'script' ? task : <p>{task}</p>}
      {type === 'script' ? (
        <ScriptTestKey
          text={key}
          setKeyText={setKeyText}
          variant={variant}
          answer={answer}
          course={course}
          isFinish={!isFinish}
          quizWordsData={quizWordsData}
          stepsCount={stepsCount}
          step={step}
          sound={sound}
          transliteration={transliteration}
        />
      ) : (
        <TestKey
          text={key}
          variant={variant}
          type={type}
          answer={answer}
          setKeyText={setKeyText}
          transliterationWord={transliterationWord}
        />
      )}
      {type === 'vocab' && (
        <WordRow
          text={word}
          sound={sound}
          isInitialPlay={!isFinish}
          course={course}
          isImage={!!image}
        />
      )}
      {image ? (
        <ImageTestOptions
          isDisable={isAnswered}
          optionsData={optionsData}
          setVariant={setVariant}
          variant={variant}
        />
      ) : (
        <ul className={styles.testSectionList}>
          {optionsData.map((item, id) => {
            return (
              <TestRow
                isDisable={isAnswered}
                key={item + id}
                variant={variant}
                item={item}
                chooseAnswer={setVariant}
              />
            );
          })}
        </ul>
      )}

      <ResultComponent
        answer={resultAnswer || answer}
        isCorrect={isCorrect}
        isVisible={isAnswered}
      />
      {isAnsweredLoading || isReviewIsLoading ? (
        <Loader />
      ) : (
        <footer className={styles.assessmentFooter}>
          {hint.trim() && <HintComponent text={hint} isVisible={!isAnswered} />}
          <div className={styles.assessmentFooterButton}>
            {isAnswered ? (
              <StepButton
                text={isLastQuestion ? 'Finish' : 'Next'}
                type={isLastQuestion ? '' : 'next'}
                onHandleClick={isLastQuestion ? handleFinishClick : handleNextClick}
              />
            ) : (
              <Button
                disabled={!variant}
                type={'primary'}
                value={'Check'}
                onClick={handleCheckClick}
              />
            )}
          </div>
        </footer>
      )}
    </div>
  );
};

export default memo(TestContent);
