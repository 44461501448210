import React, { memo, ReactFragment, useEffect, useMemo, useState } from 'react';

import AudioSound from '../../../features/atoms/AudioSound';
import { IQuizWordData, ITransliteration } from '../../../types/study';
import styles from './ScriptTestKey.module.scss';

interface ITestKeyProps {
  text: string;
  variant: string;
  course: string;
  sound?: string;
  url?: string;
  stepsCount: number;
  step: number;
  setKeyText?: (str: string) => void;
  answer?: string;
  isFinish: boolean;
  quizWordsData?: Record<string, IQuizWordData>;
  transliteration?: ITransliteration[];
}
const ScriptTestKey: React.FC<ITestKeyProps> = ({
  text,
  variant,
  answer = '',
  setKeyText,
  // course,
  isFinish,
  quizWordsData = {},
  step,
  stepsCount,
  sound,
  transliteration,
}) => {
  const [transliterationsArray, setTransliterationsArray] = useState<
    Array<string | null | ReactFragment>
  >([]);

  useEffect(() => {
    if (transliteration) {
      const readingData = transliteration.reduce(
        (acc: Array<string | null | ReactFragment>, el) => {
          if (el.letter !== '́') {
            return [...acc, el.reading || <>&nbsp;</>];
          } else {
            return acc;
          }
        },
        []
      );
      setTransliterationsArray(readingData);
    }
  }, [transliteration]);
  const modifiedText = useMemo(() => {
    const splittedText = text.match(
      // eslint-disable-next-line no-misleading-character-class
      /.[\u0300-\u0306\u0340-\u0346\u0310-\u0313\u0350-\u0353\u0314-\u0317\u0354-\u0357\u0322-\u0330\u0362-\u0370\u0331-\u0334\u0371-\u0374\u0321\u0361\u0307\u0347]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g
    );
    const newTextArr: Array<string> = [];
    if (step < stepsCount) {
      const charIndex = quizWordsData[text]?.chars.indexOf(answer);
      const restData = quizWordsData[text]?.chars.slice(charIndex);
      splittedText?.forEach((el) => {
        if (el.includes(answer)) {
          newTextArr.push('#');
        } else if (restData?.includes(el)) {
          newTextArr.push('*');
        } else {
          newTextArr.push(el);
        }
      });
    } else {
      splittedText?.forEach((el) => {
        if (el.includes(answer)) {
          newTextArr.push('#');
        } else {
          newTextArr.push(el);
        }
      });
    }

    if (setKeyText) {
      setKeyText(newTextArr?.join('') || '');
    }

    return newTextArr;
  }, [text, quizWordsData]);

  return (
    <div className={styles.scriptTestKeyContent}>
      {modifiedText.map((chr, index) => {
        return (
          <div key={index} className={styles.lettersBlock}>
            {chr === '#' ? (
              <span>{variant}</span>
            ) : chr === '́' ? (
              <span>{chr}</span>
            ) : chr === '*' ? (
              <p className={styles.blueBlock} />
            ) : chr === ' ' ? (
              <>&nbsp;&nbsp;</>
            ) : (
              <p>{chr}</p>
            )}
            {chr !== ' ' && <div>{transliterationsArray[index]}</div>}
          </div>
        );
      })}
      <AudioSound
        sound={sound}
        // sound={sound || `https://ll-app.storage.googleapis.com/audio/${course}/${text}.mp3`}
        isInitialPlay={isFinish}
      />
    </div>
  );
};

export default memo(ScriptTestKey);
