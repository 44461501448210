import React, { memo, useEffect, useMemo, useState } from 'react';

import { KANJI_TOOLTIP_MARGIN_X, KANJI_TOOLTIP_MARGIN_Y } from '../../../../constants/study';
import AudioSound from '../../../../features/atoms/AudioSound';
import KanjiTooltip from '../../../KanjiTooltip';
import styles from './vocabulary.module.scss';

interface IWordRowProps {
  text?: string;
  subText?: string;
  sound?: string;
  url?: string;
  isInitialPlay?: boolean;
  isImage?: boolean;
  course?: string;
}

const WordRow: React.FC<IWordRowProps> = ({
  text = '',
  subText,
  sound = '',
  isInitialPlay,
  course,
  isImage,
}) => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [character, setCharacter] = useState('');

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const kanjiData = useMemo(() => {
    return text?.match(/[\u4E00-\u9FAF\u4E00-\u9FFF\u3007]/g);
  }, [text]);

  const handleKanjiClick = (e: any) => {
    setIsShowPopup((prev) => {
      const positionX = e.target.offsetLeft;
      const positionY = e.target.offsetTop;
      if (prev) {
        if (positionX === x - KANJI_TOOLTIP_MARGIN_X && positionY === y - KANJI_TOOLTIP_MARGIN_Y) {
          setCharacter('');
          return false;
        }
        e.stopPropagation();

        return true;
      } else {
        setCharacter(e.target.textContent);
        return true;
      }
    });
    setX(e.target.offsetLeft + KANJI_TOOLTIP_MARGIN_X);
    setY(e.target.offsetTop + KANJI_TOOLTIP_MARGIN_Y);
  };

  useEffect(() => {
    const clickListener = () => {
      setIsShowPopup(false);
      setCharacter('');
    };
    if (isShowPopup) {
      document.addEventListener('click', clickListener);
    }
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, [isShowPopup]);

  return (
    <div className={styles.wordRow}>
      {isShowPopup && <KanjiTooltip character={character} positionX={x} positionY={y} />}
      <div>
        <p>
          <strong>
            {course === 'jp' ? (
              <>
                {text?.split('').map((chr: string, index) => {
                  if (kanjiData?.includes(chr)) {
                    return (
                      <span
                        className="hanChar"
                        data-char="$&"
                        onClick={handleKanjiClick}
                        key={`${index + chr + sound}`}
                      >
                        {chr}
                      </span>
                    );
                  } else {
                    return <>{chr}</>;
                  }
                })}
              </>
            ) : (
              <>{text}</>
            )}
          </strong>
        </p>
        {subText && <p>{subText}</p>}
      </div>
      <AudioSound sound={sound} isInitialPlay={isInitialPlay} isImage={isImage} />
    </div>
  );
};

export default memo(WordRow);
