import React, { memo, useEffect } from 'react';

import IconSound from '../../../assets/imgComponent/IconSound';
import styles from './audioSoud.module.scss';

interface IAudioComponentProps {
  sound: string | undefined;
  isInitialPlay?: boolean;
  isImage?: boolean;
  url?: string;
}

const AudioSound: React.FC<IAudioComponentProps> = ({ sound, isInitialPlay, isImage }) => {
  let audio = new Audio(sound);

  useEffect(() => {
    if (sound && isInitialPlay) {
      setTimeout(
        () => {
          play();
        },
        isImage ? 1000 : 500
      );
    }
    if (!isInitialPlay) {
      audio = new Audio(sound);
    }
  }, [sound, isInitialPlay]);

  // const checkTheAudioUrl = async () => {
  //   try {
  //     const response = await fetch(sound);
  //     if (response.ok) {
  //       // Audio URL is accessible, set it as the source and play
  //     } else {
  //       if (url) {
  //         audio = new Audio(url);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     // Audio URL is not accessible, fallback to the URL field
  //   }
  // };
  const play = () => {
    audio.play();
  };

  return (
    <button className={styles.audioContentSound} onClick={play}>
      <IconSound />
    </button>
  );
};

export default memo(AudioSound);
