import React from 'react';
import { useSelector } from 'react-redux';

import AudioSound from '../../../../features/atoms/AudioSound';
import Button from '../../../../features/atoms/Button';
import { getCurrentActivity } from '../../../../store/selectors/studySelectors';
import { ICurrentActivity, IScriptItemData } from '../../../../types/study';
import styles from './script.module.scss';

interface IScriptPrologue {
  data: Array<IScriptItemData>;
  setIsQuizStarted: any;
  isShowSpeaker: boolean;
}
const ScriptPrologue: React.FC<IScriptPrologue> = ({ data, setIsQuizStarted, isShowSpeaker }) => {
  const { text }: ICurrentActivity = useSelector(getCurrentActivity);

  return (
    <div className={styles.prologuePage}>
      <h1>{text}</h1>
      {data.map(({ _id, english, chr, url }) => {
        return (
          <div key={_id} className={styles.lettersRow}>
            <div className={styles.lettersContent}>
              <p>{chr}</p>
              <p>{english}</p>
            </div>
            {isShowSpeaker && (
              <AudioSound
                sound={url}
                // sound={`https://ll-app.storage.googleapis.com/audio/${course}/${chr}.mp3`}
              />
            )}
          </div>
        );
      })}
      <Button type={'primary'} value={'Start the quiz'} onClick={() => setIsQuizStarted(true)} />
    </div>
  );
};

export default ScriptPrologue;
