import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TEST_ITEM_DATA_INITIAL } from '../../../../constants/study';
import ProgressLine from '../../../../features/atoms/ProgressLine';
import { getCurrentActivity } from '../../../../store/selectors/studySelectors';
import {
  ICurrentActivity,
  IQuizWordData,
  IScriptItemData,
  ITestItemData,
} from '../../../../types/study';
import { getOptionsForScript } from '../../../../utils/study';
import TestContent from '../../../TestComponents/TestContent';
import main from '../Assessment/assigments.module.scss';
import styles from './script.module.scss';

interface IVocabularyQuizProps {
  data: Array<IScriptItemData>;
  stepsCount: number;
  numOfQuestions: Array<Array<number>>;
  quizWordsData: Record<string, IQuizWordData>;
}

const ScriptQuiz: React.FC<IVocabularyQuizProps> = ({
  data,
  stepsCount,
  numOfQuestions,
  quizWordsData,
}) => {
  const { text, id }: ICurrentActivity = useSelector(getCurrentActivity);

  const [step, setStep] = useState<number>(0);
  const [testData, setTestData] = useState<ITestItemData>(TEST_ITEM_DATA_INITIAL);
  const [questionsPositions, setQuestionsPositions] =
    useState<Array<Array<number>>>(numOfQuestions);
  const [checkedItemsCount, setCheckedItemsCount] = useState<number>(0);
  const isLastQuestion = step === questionsPositions.length - 1;

  useEffect(() => {
    const [currentScriptNumber, currentScriptQuestionNumber] = questionsPositions[step];
    const { _id, course, description, chr, words } = data[currentScriptNumber];
    const { meanings, spelling, transliteration, url } = words[currentScriptQuestionNumber];

    const value: ITestItemData = {
      answer: chr,
      course: course,
      hint: description,
      key: spelling,
      options: getOptionsForScript(data, chr),
      task: (
        <p>
          Complete the word for <strong>{meanings}</strong>:
        </p>
      ),
      taskText: `Complete the word for ${meanings}:`,
      _id,
      url,
      sound: url,
      word: spelling,
      transliteration: transliteration,
    };
    setTestData(value);
  }, [step]);

  useEffect(() => {
    setQuestionsPositions(numOfQuestions);
  }, [numOfQuestions]);

  return (
    <div className={`${main.assessment} ${styles.vocabularyQuiz}`}>
      <ProgressLine value={(checkedItemsCount / stepsCount) * 100} />
      <h1>{text}</h1>
      <TestContent
        type={'script'}
        data={testData}
        step={step}
        setStep={setStep}
        stepsCount={stepsCount}
        setCheckedItemsCount={setCheckedItemsCount}
        id={id}
        isLastQuestion={isLastQuestion}
        allData={data}
        numOfQuestions={questionsPositions}
        quizWordsData={quizWordsData}
        setQuestionsPositions={setQuestionsPositions}
      />
    </div>
  );
};

export default ScriptQuiz;
